import React from 'react'

interface HeroProps {
  type?: 'business' | 'investor'
}

const Hero: React.FunctionComponent<HeroProps> = (props): JSX.Element => {
  const { type, children } = props

  const checkTypes = (key: HeroProps['type'] | undefined): string => {
    switch (key) {
      case 'investor':
        return 'tm-background-gradient-investor'
      default:
        return 'tm-background-gradient'
    }
  }

  return (
    <section className="uk-light">
      <div
        className={`uk-background-norepeat uk-background-cover uk-background-bottom-center ${checkTypes(
          type
        )} uk-section`}
      >
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div style={{ paddingTop: 121 }}>{children}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
